@font-face {
font-family: "Overused Grotesk";
src:
  url("../fonts/OverusedGrotesk-VF.woff2") format("woff2-variations");
font-weight: 300 900;
}

* {
  font-family: 'Overused Grotesk', sans-serif;
  color: #100A1D;
}

body {
  background-color: #FDFCFF;
}

.centered-content {
  margin: 0 auto;
  max-width: 700px;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
}

.home {
  margin: 60px auto 60px auto;
}

.home-name {
  font-size: 64px;
}

.home-caption {
  font-size: 32px;
  color: #6D44C1;
}

.home-name, .home-caption {
  text-align: center;
  margin: 0px;
}

.navbar {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.nav-logo {
  font-size: 32px;
}

.navbar ul {
  display: flex;
  gap: 20px;
}

.navbar li {
  list-style-type: none;
  border-radius: 10px;
  padding: 10px;
  outline: #D9D9D9 solid 1px;
}

.navbar a {
  text-decoration: none;
}

.container .navbar {
  justify-content: space-between;
} 

.container .about {
  justify-content: space-between;
}

.about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  margin: 20px;
}

.about-picture {
  max-width: 100%;
  height: 100%;
  border-radius: 10px;
}

.project-list {
  display: flex;
  flex-direction: column;
  margin: 20px;

  /* ensure cards are always centered */
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.project-card {
  outline: #D9D9D9 solid 1px;
  position: relative;
  max-width: 600px;
  align-content: center;
  justify-content: center;
  max-height: 100%;
}

.project-title {
  font-size: 24px;
  background-color: white;
  padding: 8px;
}

.project-links li {
  list-style-type: none;
  margin: 0px 10px 0px 10px;
  background-color: white;
  padding: 10px;

}

.project-links {
  margin: 8px;
}

.project-card, .project-title, .project-links li {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 15px;
}

.project-card img{
  max-width: 100%;
  max-height: 100%;

  border-radius: 10px;
  display: block;
  margin: auto;
  flex-basis: fit-content;
}

.project-card .project-title {
  position: absolute;
  top: 20px;
  left: 20px;
}

.project-card .project-links {
  position: absolute;
  bottom: 20px;
  right: 20px;

  display: flex;
  justify-content: space-around;
}

.project-links {
  padding: 0px;
}

.project-links a {
  text-decoration: none;
}

.divider {
  font-size: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 40px auto 10px auto;
}

/*
.divider:before, .divider:after {
  flex: 1;
  content: '';
  padding: 1px;
  background-color: #6D44C1;
  margin: 50px;
}

.divider:before {
  margin-left: 0px;
}

.divider:after {
  margin-right: 0px;
}
*/

.int-button {
  transition-duration: 0.4s;
}

.int-button:hover {
  background-color: #D9D9D9;
}

@media screen and (max-width: 680px) {
  .about {
    flex-direction: column;
    flex-flow: column-reverse;
    align-items: center;
  }

  .home-name {
    font-size: 52px;
  }
  
  .home-caption {
    font-size: 26px;
  }

  .project-card img{
    border-radius: 0px;
  }

  .project-card .project-title {
    position: relative;
    top: 0px;
    left: 0px;
    border-radius: 15px 15px 0px 0px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 0%);
  }
  
  .project-card .project-links {
    position: relative;
    bottom: 0px;
    right: 0px;
    justify-content: flex-end;
  }

  .project-links li {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 0%);
    outline: #D9D9D9 solid 1px;
  }
}